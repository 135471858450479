body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f4f4f4;
}
  
  .App {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .App button {
    padding: 10px 20px;
    border: none;
    background-color: #007BFF;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .App button:hover {
    background-color: #0056b3;
  }
  
  .App input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    height: 15px;
    width: 400px;
  }
  
  .App pre {
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    overflow-x: scroll;
    text-align: left;
    font-size: 16px;
  }
  
  .App label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .field-row,
  .section-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .field-row > button,
  .section-row > button {
    margin-left: 10px;
  }
  
  .field-row,
  .section-row {
    width: 100%;
  }
  
  .field-row label,
  .section-row label {
    flex-basis: 150px;
  }
  
  .field-row input,
  .section-row input {
    flex-grow: 1;
    margin-right: 10px;
  }

  .section {
    margin-bottom: 20px;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .banner {
    background-color: #0078d7;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.0rem;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.horizontal-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
  }
  
  .menu-item {
    color: #fff;
    text-decoration: none;
    font-size: 0.8rem;
    transition: color 0.2s;
  }
  
  .menu-item:hover {
    color: #cce6ff;
  }

  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-title {
    font-size: 1.5em;
  }
  
  .horizontal-menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .menu-item {
    margin: 0 5px;
  }
  
  @media screen and (max-width: 767px) {
    .header-title {
      font-size: 1.2em;
    }
    
    .horizontal-menu {
      font-size: 0.8em;
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #0078d7;
    padding: 10px 0;
    text-align: left;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .footer-content {
    margin-left: 20px;
  }
  
  .buymeacoffee {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    margin-right: 30px;
  }
  
  .buymeacoffee:hover {
    text-decoration: underline;
  }
  
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-form .form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .header-form .form-label {
    width: 40%;
    text-align: center;
  }
  
  .header-form input {
    width: 55%;
  }
  
  .update-header-button {
    display: block;
    margin: 20px auto;
  }
  
  .footer-form .form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .footer-form .form-label {
    width: 40%;
    text-align: center;
  }
  
  .footer-form input {
    width: 55%;
  }
  
  .update-footer-button {
    display: block;
    margin: 20px auto;
  }
  